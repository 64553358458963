#h {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1002;
    margin: 0;
}

.menu-list {
    border-bottom: 1px solid #414750; 
}